import Project from '../components/Project';

const projects = [
  { title: 'Nothing here yet :(', description: '', link: '#' },
  // { title: 'Project 2', description: 'Description of project 2', link: '#' },
  // Add more projects here
];

const Projects: React.FC = () => (
  <div>
    <h1>Projects</h1>
    {projects.map((project, index) => (
      <Project key={index} {...project} />
    ))}
  </div>
);

export default Projects;
