import './Home.css'

const Home: React.FC = () => (
  <div className="home">
    <div className="memoji">
      <img src="/assets/images/memoji.png" alt="memoji"/>
    </div>
    <div className="about">
      <h1>Hello!</h1>
      <p>I'm Lukas <span className="alekna"><b>Alekna</b></span>vicius, full-stack web developer.</p>
      <p>I've been working as a software engineer for ~6 years. Currently working as a full-time back-end engineer.</p>
      <br/>
      <p>This page is still under construction 🚧</p>
    </div>
  </div>
);

export default Home;
