import React from 'react';
import { Link } from 'react-router-dom';
import { blogPosts } from '../data/blogPosts';
import './Blog.css';

const Blog: React.FC = () => {
  return (
    <div>
      <h1>Blog</h1>
      {blogPosts.map(post => (
        <div key={post.id} className="blog-summary">
          <h2>{post.title}</h2>
          <time>
            {
              new Date(post.date).toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' })
            }
          </time>
          <p>{post.summary}</p>
          <Link to={`/blog/${post.id}`}>Read more</Link>
        </div>
      ))}
    </div>
  );
};

export default Blog;
