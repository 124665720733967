export interface BlogPost {
  id: number;
  title: string;
  date: string;
  summary: string; // Short summary or snippet for the list view
  content: string; // Full content of the blog post
}

export const blogPosts: BlogPost[] = [
  {
    id: 1,
    title: 'First Blog Post',
    date: '2024-09-10',
    summary: 'A brief summary of the first blog post.',
    content: '<p>This is the full content of the first blog post.</p>'
  },
  // Add more blog posts as needed
];
