import { Link } from 'react-router-dom';
import './Navbar.css'

const Navbar: React.FC = () => (
  <nav>
    <ul className="navbar">
      <div className="title">
        <Link to="/"><span className="alekna">Alekna</span>Codes</Link>
      </div>
      <div className="items">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/projects">Projects</Link></li>
        <li><Link to="/blog">Blog</Link></li>
      </div>
    </ul>
  </nav>
);

export default Navbar;
